<template>
  <v-dialog v-model="localValue" width="600">
    <v-card class="card">
      <v-card-title>
        <div>
          <v-btn small absolute top right icon @click="localValue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <v-col class="pl-0" cols="12">
          <h3 class="custom-title-secondary">
            <span>{{ selectDataProps | moment('dddd, LL') }}</span> ás
            <span>{{ selectHourProps }}</span>
          </h3>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <div v-if="!loggedIn">
            <v-text-field
              name="name"
              label="Nome"
              v-model="data.name"
              :rules="rules.name"
            ></v-text-field>

            <v-text-field
              name="email"
              label="E-mail"
              v-model="data.email"
              :rules="rules.email"
            ></v-text-field>
          </div>

          <span>Selecione a forma de contato:</span>
          <v-radio-group row v-model="radios">
            <v-radio label="Telefone" value="phone"></v-radio>
            <v-radio label="Skype" value="nameSkype"></v-radio>
          </v-radio-group>

          <v-text-field
            v-model="data[radios]"
            :rules="radios == 'phone' ? rules.phoneRules : rules.skypeRules"
            :label="radios == 'phone' ? 'Telefone' : 'Skype'"
            v-mask="
              radios == 'phone' ? ['(##)####-####', '(##)#####-####'] : nomask
            "
            validate-on-blur
          ></v-text-field>
          <v-row no-gutters class="mt-6">
            <v-col cols="3">
              <v-btn
                class="ml-0 custom-btn-green-inverse rounden-0"
                color="#305925"
                outlined
                @click="localValue = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="custom-btn-green rounden-0"
                :loading="loading"
                @click="confirmDayTime()"
              >
                Confirmar agendamento
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { authComputed } from '@state/helpers'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    selectHourProps: {},
    selectDataProps: {
      type: Object,
      default: false,
    },
    selectTypeProps: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      nomask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
      valid: true,
      radios: 'phone',
      rules: {
        phoneRules: [
          (v) => (!!v && v.length >= 14) || 'Por favor, preencha o telefone',
        ],
        skypeRules: [(v) => !!v || 'Por favor, preencha o Skype'],
        name: [(v) => !!v || 'Por favor, preencha o nome'],
        email: [(v) => !!v || 'Por favor, preencha o email'],
      },
      data: {
        date: `${this.selectDataProps.format('YYYY-MM-DD')}`,
        hour: `${this.selectHourProps}`,
        type: this.selectTypeProps,
        phone: '',
        nameSkype: '',
        name: '',
        email: '',
      },
    }
  },

  computed: {
    ...authComputed,
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    confirmDayTime() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$store
          .dispatch('diaries/createScheduling', this.currentData())
          .then(() => {
            this.loading = false
            this.localValue = false
            this.$store.commit('diaries/showAlert', true)
          })
      }
    },
    currentData() {
      let sendData = Object.assign({}, this.data)
      if (this.radios == 'phone') {
        sendData.phone = sendData.phone.replace(/\D/g, '')
        delete sendData.nameSkype
      } else {
        delete sendData.phone
      }
      if (this.loggedIn) {
        sendData.email = this.currentUser.email
        sendData.name = this.currentUser.name
      }
      return sendData
    },
  },
}
</script>

<style lang="scss">
@import '@design';
.card {
  border-radius: 14px;
}
</style>
