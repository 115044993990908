<template>
  <div>
    <v-col class="ma-0 pa-0">
      <span class="subtitle-1 font-weight-black">
        Agendamento confirado com sucesso!
      </span>
      <br />
      Entraremos em contato no dia
      {{ diaries.startTime | moment('DD/MM/YYYY') }} ás
      {{ diaries.startTime | moment('H:mm') }} <br />
      através do {{ !diaries.phone ? 'seu usuário de skype' : 'telefone' }}:
      {{ !diaries.phone ? diaries.nameSkype : diaries.phone | toTEL }}
    </v-col>
    <v-col class="ma-0 pa-0 py-3" v-if="loggedIn">
      <v-btn @click="cancel">Reagendar</v-btn>
    </v-col>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { authComputed } from '@state/helpers'
export default {
  computed: {
    ...mapGetters({
      diaries: 'diaries/scheduling',
    }),
    loggedIn: authComputed.loggedIn,
  },
  methods: {
    cancel() {
      this.$store.dispatch('diaries/cancelScheduling')
    },
  },
}
</script>
