<template>
  <div class="ma-0 pa-0" v-if="!loading">
    <calendarUser v-if="!valueAlert" :type="type"></calendarUser>
    <sucessDiaries v-if="valueAlert"></sucessDiaries>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import calendarUser from './calendarUser.vue'
import sucessDiaries from './sucessDiaries.vue'
export default {
  components: {
    calendarUser,
    sucessDiaries,
  },
  props: {
    typeId: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      type: '',
    }
  },
  computed: {
    ...mapGetters({
      valueAlert: 'diaries/valueAlert',
    }),
  },

  methods: {
    sucessDiaries() {
      this.$emit('sucessDiaries', true)
    },
  },
  async created() {
    this.loading = true
    this.scheduling = await this.$store.dispatch(
      'diaries/getSchedulingByType',
      this.typeId
    )
    this.type = await this.$store.dispatch(
      'diaries/getIntervalType',
      this.typeId
    )
    this.loading = false
  },
  watch: {
    valueAlert(newValue, oldValue) {
      if (newValue) this.sucessDiaries()
    },
  },
}
</script>
