<template>
  <v-row no-gutters>
    <v-col cols="12">
      <slot name="title" v-if="!!$slots.title">
        <div class="pa-4">
          <p class=" font-weight-black">
            Selecione o dia e horário de sua conveniência
          </p>
          <p>
            Após o agendamento, um membro na nossa equipe realizará a ligação
            durante o período selecionado por você.
          </p>
        </div>
      </slot>
    </v-col>
    <v-row class="v-color" no-gutters>
      <v-row no-gutters justify="space-around">
        <v-btn
          class="mx-0"
          :disabled="!!beforeToday"
          color="#FFFFFF"
          fab
          small
          @click="prev()"
        >
          <v-icon dark>
            keyboard_arrow_left
          </v-icon>
        </v-btn>
        <v-col v-for="(itemData, index) in avaliable" :key="index">
          <div class="text-center">
            <span class="font-weight-black">
              {{ itemData.date | moment('dddd') }}
            </span>
            <br />
            <span>{{ itemData.date | moment('L') }}</span>
          </div>
          <v-col
            v-for="(item, index) in itemData.availableTimes"
            :key="index"
            class="text-center pa-0 ma-1 py-2"
          >
            <v-btn
              class="mx-0"
              :loading="loading"
              outlined
              small
              rounded
              color="primary"
              @click="show(itemData.date, item.time)"
              :disabled="!itemData.isAvalible || !item.isAvailable"
            >
              {{ item.time }} hs
            </v-btn>
          </v-col>
        </v-col>
        <v-btn fab small class="mx-0" color="#FFFFFF" @click="next()">
          <v-icon dark>
            keyboard_arrow_right
          </v-icon>
        </v-btn>
      </v-row>
    </v-row>
    <confirmation
      v-if="!!showConfirmation"
      v-model="showConfirmation"
      :selectTypeProps="type.id"
      :selectHourProps="selectHour"
      :selectDataProps="selectDate"
    ></confirmation>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import confirmation from './confirmation.vue'
import { format } from 'path'
export default {
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      loading: false,
      today: this.$moment()
        .second(0)
        .milliseconds(0),
      hoursAvaliable: [
        {
          h: '08',
          isAvailable: true,
        },
      ],
      showConfirmation: false,
      selectHour: '',
      selectDate: '',
    }
  },

  components: {
    confirmation,
  },
  methods: {
    initial() {
      let initialDay = this.$moment(this.today).add(
        this.type.intervalDay,
        this.type.intervalType
      )
      let initialEndDay = this.$moment(initialDay).add(this.days, 'days')
      this.getAvalibleTimes(initialDay, initialEndDay)
    },

    next() {
      let initialDay = this.$moment(this.lastDay).add(1, 'days')
      let initialEndDay = this.$moment(this.lastDay).add(this.days, 'days')
      this.getAvalibleTimes(initialDay, initialEndDay)
    },
    prev() {
      let initialDay = this.$moment(this.firstDay).subtract(1, 'days')
      let initialEndDay = this.$moment(this.firstDay).subtract(
        this.days,
        'days'
      )
      this.getAvalibleTimes(initialEndDay, initialDay)
    },
    show(date, h) {
      // alert(date, h)
      this.selectDate = this.$moment(date)
        .hour(0)
        .minute(0)
        .second(0)
        .milliseconds(0)
      this.selectHour = h
      this.showConfirmation = true
    },

    async getAvalibleTimes(startDay, endDay) {
      let filter = {
        typeId: this.type.id,
        startDay: startDay.format('YYYY-MM-DD H:mm'),
        endDay: endDay.format('YYYY-MM-DD'),
      }
      this.loading = true
      await this.$store.dispatch('diaries/getListAvaliable', filter)
      this.loading = false
    },
  },

  computed: {
    ...mapGetters({
      avaliable: 'diaries/listAvaliable',
    }),
    firstDay() {
      let tempFirsttday = this.avaliable[0] || null
      if (tempFirsttday) {
        return this.$moment(tempFirsttday.date)
          .hour(0)
          .minute(0)
          .second(0)
          .milliseconds(0)
      } else {
        return null
      }
    },
    lastDay() {
      let tempLastday = this.avaliable[this.avaliable.length - 1] || null
      if (tempLastday) {
        return this.$moment(tempLastday.date)
          .hour(0)
          .minute(0)
          .second(0)
          .milliseconds(0)
      } else {
        return null
      }
    },
    beforeToday() {
      if (!this.firstDay || !this.today) return false
      let now = this.$moment(this.today).add(
        this.type.intervalDay,
        this.type.intervalType
      )
      return now.format('YYYY-MM-DD') == this.firstDay.format('YYYY-MM-DD')
    },
    days() {
      if (this.$vuetify.breakpoint.xsOnly) return 2
      if (this.$vuetify.breakpoint.smAndDown) return 3
      if (this.$vuetify.breakpoint.mdAndUp) return 6
    },
  },

  mounted() {
    this.initial()
  },

  watch: {
    days: {
      handler(newVal, oldVal) {
        this.initial()
      },
      immediate: false,
    },
  },
}
</script>

<style></style>
